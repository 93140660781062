export default {
    GET_ORDER_STATS_REQUEST: 'GET_ORDER_STATS_REQUEST',
    GET_ORDER_STATS_SUCCESS: 'GET_ORDER_STATS_SUCCESS',
    GET_ORDER_STATS_FAILURE: 'GET_ORDER_STATS_FAILURE',
    
    GET_CUSTOMER_STATS_REQUEST: 'GET_CUSTOMER_STATS_REQUEST',
    GET_CUSTOMER_STATS_SUCCESS: 'GET_CUSTOMER_STATS_SUCCESS',
    GET_CUSTOMER_STATS_FAILURE: 'GET_CUSTOMER_STATS_FAILURE',
    
    GET_PRODUCT_STATS_REQUEST: 'GET_PRODUCT_STATS_REQUEST',
    GET_PRODUCT_STATS_SUCCESS: 'GET_PRODUCT_STATS_SUCCESS',
    GET_PRODUCT_STATS_FAILURE: 'GET_PRODUCT_STATS_FAILURE',
};