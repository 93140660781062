import userConstants from './constants';
import {extractApiErrorMessage} from '../utils';

const initialState = () => (
  {
    users: [],
    numUsers: undefined,
    loadingUsers: false,
    loadingUsersError: undefined,

    allRoles: ["common", "admin"],

    selectedUser: undefined,
    updatedUser: undefined,
    loadingSelectedUser: false,
    loadingSelectedUserError: undefined,
    loadingSelectedUserSuccess: undefined,

    addingUser: false,
    addingUserSuccess: undefined,
    addingUserError: undefined
  }
);

// reducer
export default (state = initialState(), action) => {
  switch (action.type) {
    case userConstants.LIST_USERS_REQUEST:
      return {...state, loadingUsers: true};

    case userConstants.LIST_USERS_SUCCESS:
      return {
        ...state, 
        users: action.payload.accounts, 
        numUsers: action.payload.total, 
        loadingUsers: false,
        loadingUsersError: undefined
      };

    case userConstants.LIST_USERS_FAILURE:
      return {
        ...state, 
        loadingUsers: false,
        loadingUsersError: extractApiErrorMessage(action.payload)
      };

    case userConstants.ADD_USER_REQUEST:
      return {
        ...state, 
        addingUser: true,
        addingUserError: undefined,
        addingUserSuccess: undefined
      };

    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state, 
        addingUser: false,
        addingUserError: undefined,
        addingUserSuccess: `Successfully added user: ${action.payload.email}`
      };

    case userConstants.ADD_USER_FAILURE:
      return {
        ...state, 
        addingUser: false,
        addingUserError: extractApiErrorMessage(action.payload),
        addingUserSuccess: undefined
      };
    
    case userConstants.GET_USER_REQUEST:
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        loadingSelectedUser: true,
        loadingSelectedUserError: undefined,
        loadingSelectedUserSuccess: undefined
      };

    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
        updatedUser: action.payload,
        loadingSelectedUser: false,
        loadingSelectedUserError: undefined,
        loadingSelectedUserSuccess: undefined
      };
    
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
        updatedUser: action.payload,
        loadingSelectedUser: false,
        loadingSelectedUserError: undefined,
        loadingSelectedUserSuccess: `Successfully updated user: ${action.payload.email}`
      };

    case userConstants.GET_USER_FAILURE:
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loadingSelectedUser: false,
        loadingSelectedUserError: extractApiErrorMessage(action.payload),
        loadingSelectedUserSuccess: undefined
      };
    
    case userConstants.EDIT_SELECTED_USER:
      const updatedUser = {...state.updatedUser, ...action.payload};
      return {...state, updatedUser}

    default:
      return state
  }
};
