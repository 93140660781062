export default {
    LIST_SUPPLIERS_REQUEST: 'LIST_SUPPLIERS_REQUEST',
    LIST_SUPPLIERS_SUCCESS: 'LIST_SUPPLIERS_SUCCESS',
    LIST_SUPPLIERS_FAILURE: 'LIST_SUPPLIERS_FAILURE',

    GET_SUPPLIER_REQUEST: 'GET_SUPPLIER_REQUEST',
    GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',
    GET_SUPPLIER_FAILURE: 'GET_SUPPLIER_FAILURE',

    ADD_SUPPLIER_REQUEST: 'ADD_SUPPLIER_REQUEST',
    ADD_SUPPLIER_SUCCESS: 'ADD_SUPPLIER_SUCCESS',
    ADD_SUPPLIER_FAILURE: 'ADD_SUPPLIER_FAILURE',

    DELETE_SUPPLIER_REQUEST: 'DELETE_SUPPLIER_REQUEST',
    DELETE_SUPPLIER_SUCCESS: 'DELETE_SUPPLIER_SUCCESS',
    DELETE_SUPPLIER_FAILURE: 'DELETE_SUPPLIER_FAILURE',

    LIST_SUPPLIER_PRODUCTS_REQUEST: 'LIST_SUPPLIER_PRODUCTS_REQUEST',
    LIST_SUPPLIER_PRODUCTS_SUCCESS: 'LIST_SUPPLIER_PRODUCTS_SUCCESS',
    LIST_SUPPLIER_PRODUCTS_FAILURE: 'LIST_SUPPLIER_PRODUCTS_FAILURE',

    DELETE_SUPPLIER_PRODUCT_REQUEST: 'DELETE_SUPPLIER_PRODUCT_REQUEST',
    DELETE_SUPPLIER_PRODUCT_SUCCESS: 'DELETE_SUPPLIER_PRODUCT_SUCCESS',
    DELETE_SUPPLIER_PRODUCT_FAILURE: 'DELETE_SUPPLIER_PRODUCT_FAILURE',

    ADD_SUPPLIER_PRODUCT_REQUEST: 'ADD_SUPPLIER_PRODUCT_REQUEST',
    ADD_SUPPLIER_PRODUCT_SUCCESS: 'ADD_SUPPLIER_PRODUCT_SUCCESS',
    ADD_SUPPLIER_PRODUCT_FAILURE: 'ADD_SUPPLIER_PRODUCT_FAILURE',
};