import constants from './constants';
import {extractApiErrorMessage} from '../utils';

const initialState = () => (
  {
    customers: [],
    numCustomers: undefined,
    loadingCustomers: true,
    loadingCustomersError: undefined,
    loadingCustomersSuccess: undefined,

    addingCustomer: false,
    addingCustomerSuccess: undefined,
    addingCustomerError: undefined,
    clearCustomer: false,

    selectedCustomer: undefined,
    loadingSelectedCustomer: true,
    loadingSelectedCustomerSuccess: undefined,
    loadingSelectedCustomerError: undefined,
    
    redirectToCustomerListView: false,

    allTitles: ["Mr", "Mrs", "Ms", "Dr"],
    allContactTypes: [
      {contactType: "email", displayText: "Email Address"},
      {contactType: "cell", displayText: "Mobile No."},
      {contactType: "tel", displayText: "Telephone No."}
    ]
  }
);

// reducer
export default (state = initialState(), action) => {
  state.redirectToCustomerListView = false;

  switch (action.type) {
    case constants.LIST_CUSTOMERS_REQUEST:
      return {...state, loadingCustomers: true};

    case constants.LIST_CUSTOMERS_SUCCESS:
      return {
        ...state, 
        customers: action.payload.customers, 
        numCustomers: action.payload.total, 
        loadingCustomers: false,
        loadingCustomersError: undefined
      };

    case constants.LIST_CUSTOMERS_FAILURE:
      return {
        ...state, 
        loadingCustomers: false,
        loadingCustomersError: extractApiErrorMessage(action.payload)
      };

    case constants.ADD_CUSTOMER_REQUEST:
      return {
        ...state, 
        addingCustomer: true,
        addingCustomerError: undefined,
        addingCustomerSuccess: undefined
      };

    case constants.ADD_CUSTOMER_SUCCESS:
      return {
        ...state, 
        addingCustomer: false,
        addingCustomerError: undefined,
        addingCustomerSuccess: `Successfully added customer: ${action.payload.customer_code}`,
        clearCustomer: true
      };

    case constants.ADD_CUSTOMER_FAILURE:
      return {
        ...state, 
        addingCustomer: false,
        addingCustomerError: extractApiErrorMessage(action.payload),
        addingCustomerSuccess: undefined
      };
    
    case constants.TOGGLE_CLEAR_CUSTOMER:
      return {...state, clearCustomer: !state.clearCustomer}
    
    case constants.GET_CUSTOMER_REQUEST:
      return {
        ...state,
        loadingSelectedCustomer: true,
        loadingSelectedCustomerSuccess: undefined,
        loadingSelectedCustomerError: undefined
      }
    
    case constants.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        selectedCustomer: action.payload,
        loadingSelectedCustomer: false,
        loadingSelectedCustomerSuccess: undefined,
        loadingSelectedCustomerError: undefined
      }
    
    case constants.GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loadingSelectedCustomer: false,
        loadingSelectedCustomerSuccess: undefined,
        loadingSelectedCustomerError: extractApiErrorMessage(action.payload)
      }

    case constants.UPDATE_CUSTOMER_REQUEST:
      return {
        ...state, 
        loadingSelectedCustomer: true,
        loadingSelectedCustomerError: undefined,
        loadingSelectedCustomerSuccess: undefined
      };

    case constants.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingSelectedCustomer: false,
        loadingSelectedCustomerError: undefined,
        loadingSelectedCustomerSuccess: `Successfully updated customer: ${state.selectedCustomer.customer_code}`,
        selectedCustomer: action.payload,
        editedCustomer: action.payload
      };

    case constants.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state, 
        loadingSelectedCustomer: false,
        loadingSelectedCustomerError: extractApiErrorMessage(action.payload),
        loadingSelectedCustomerSuccess: undefined
      };

    case constants.DELETE_CUSTOMER_REQUEST:
      return {
        ...state, 
        loadingSelectedCustomer: true,
        loadingSelectedCustomerError: undefined,
        loadingSelectedCustomerSuccess: undefined
      };

    case constants.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingSelectedCustomer: false,
        loadingSelectedCustomerError: undefined,
        loadingSelectedCustomerSuccess: undefined,
        selectedCustomer: undefined,
        editedCustomer: undefined,
        redirectToCustomerListView: true
      };

    case constants.DELETE_CUSTOMER_FAILURE:
      return {
        ...state, 
        loadingSelectedCustomer: false,
        loadingSelectedCustomerError: extractApiErrorMessage(action.payload),
      };
    
    case constants.EDIT_SELECTED_CUSTOMER:
      const selectedCustomer = {...state.selectedCustomer, ...action.payload};
      return {...state, selectedCustomer}

    case constants.ADD_ADDRESS_REQUEST:
      return {...state, loadingAddresses: true};

    case constants.ADD_ADDRESS_SUCCESS:
      if (state.selectedCustomer.customer_code === action.payload.customer_code) {
        state.selectedCustomer.addresses.push(action.payload);
      }
      return {
        ...state, 
        loadingAddresses: false,
        loadingAddressesError: undefined
      };

    case constants.ADD_ADDRESS_FAILURE:
      return {
        ...state, 
        loadingAddresses: false,
        loadingAddressesError: extractApiErrorMessage(action.payload)
      };

    case constants.UPDATE_ADDRESS_REQUEST:
      return {...state, loadingAddresses: true};

    case constants.UPDATE_ADDRESS_SUCCESS:
      if (state.selectedCustomer.customer_code === action.payload.customer_code) {
        state.selectedCustomer.addresses.forEach(address => {
          if (address.address_id === action.payload.address_id) {
            address.address_description = action.payload.address_description;
            address.address = action.payload.address;
            address.postal_code = action.payload.postal_code;
            return;
          }
        });
      }
      return {
        ...state, 
        loadingAddresses: false,
        loadingAddressesError: undefined
      };

    case constants.UPDATE_ADDRESS_FAILURE:
      return {
        ...state, 
        loadingAddresses: false,
        loadingAddressesError: extractApiErrorMessage(action.payload)
      };

    case constants.DELETE_ADDRESS_REQUEST:
      return {
        ...state, 
        loadingAddresses: false,
        loadingAddressesError: undefined
      };

    case constants.DELETE_ADDRESS_SUCCESS:
      const addresses = state.selectedCustomer.addresses.filter(address => 
        address.address_id !== action.payload.address_id);
      return {
        ...state,
        selectedCustomer: {...state.selectedCustomer, addresses},
        loadingAddresses: false,
        loadingAddressesError: undefined
      };

    case constants.DELETE_ADDRESS_FAILURE:
      return {
        ...state, 
        loadingAddresses: false,
        loadingAddressesError: extractApiErrorMessage(action.payload)
      };

    case constants.DELETE_CONTACT_DETAIL_REQUEST:
      return {
        ...state, 
        loadingContactDetails: false,
        loadingContactDetailsError: undefined
      };

    case constants.DELETE_CONTACT_DETAIL_SUCCESS:
      const contact_details = state.selectedCustomer.contact_details.filter(c => 
        c.contact_detail_id !== action.payload.contact_detail_id);
      return {
        ...state,
        selectedCustomer: {...state.selectedCustomer, contact_details},
        loadingContactDetails: false,
        loadingAddressesError: undefined
      };

    case constants.DELETE_CONTACT_DETAIL_FAILURE:
      return {
        ...state, 
        loadingContactDetails: false,
        loadingContactDetailsError: extractApiErrorMessage(action.payload)
      };
    
    case constants.SET_ADD_CONTACT_DETAIL_MODE:
      return {
        ...state,
        contactDetailAddMode: action.payload.mode
      }

    case constants.ADD_CONTACT_DETAIL_REQUEST:
      return {
        ...state, 
        contactDetailAddMode: "LOADING"
      };

    case constants.ADD_CONTACT_DETAIL_SUCCESS:
      if (state.selectedCustomer.customer_code === action.payload.customer_code) {
        state.selectedCustomer.contact_details.push(action.payload);
      }
      return {
        ...state, 
        contactDetailAddMode: undefined
      };

    case constants.ADD_CONTACT_DETAIL_FAILURE:
      return {
        ...state, 
        loadingAddressesError: extractApiErrorMessage(action.payload),
        contactDetailAddMode: "INPUT"
      };
    
    case constants.SET_EDIT_CONTACT_DETAIL_MODE:
      const contactDetails = state.selectedCustomer.contact_details;
      contactDetails.forEach(c => {
        if (c.contact_detail_id === action.payload.contactDetailId) {
          c.editMode = action.payload.mode;
          return;
        }
      });
      return {
        ...state,
        selectedCustomer: {...state.selectedCustomer, contact_details: contactDetails}
      }
    
    case constants.EDIT_CONTACT_DETAIL_FIELDS:
      const contactDetailsToEdit = state.selectedCustomer.contact_details;
      contactDetailsToEdit.forEach(c => {
        if (c.contact_detail_id === action.payload.contactDetailId) {
          c.contact_type = action.payload.fields.contact_type;
          c.contact_value = action.payload.fields.contact_value;
          return;
        }
      });
      return {
        ...state,
        selectedCustomer: {...state.selectedCustomer, contact_details: contactDetailsToEdit}
      }

    case constants.UPDATE_CONTACT_DETAIL_REQUEST:
      return {
        ...state
      };

    case constants.UPDATE_CONTACT_DETAIL_SUCCESS:
      return {
        ...state, 
        selectedCustomer: {...state.selectedCustomer, contact_details: setContactDetails(state, action.payload, undefined)}
      };

    case constants.UPDATE_CONTACT_DETAIL_FAILURE:
      return {
        ...state, 
        loadingAddressesError: extractApiErrorMessage(action.payload),
      };

    default:
      return state
  }
};

const setContactDetails = (state, contactDetail, mode) => {
  const contactDetails = state.selectedCustomer.contact_details;
  contactDetails.forEach(c => {
    if (c.contact_detail_id === contactDetail.contact_detail_id) {
      c.editMode = mode;
      c.contact_type = contactDetail.contact_type;
      c.contact_value = contactDetail.contact_value;
      return;
    }
  });
  return contactDetails;
}