export const extractApiErrorMessage = (payload) => (
    (payload.response && payload.response.message) || payload.message
);

export const dateStrToIsoString = (dateStr, failureAction) => {    
  try {
    return new Date(dateStr).toISOString();
  } catch (err) {
    console.error(err);
    throw failureAction;
  }
}

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));