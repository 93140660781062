import constants from './constants';
import {extractApiErrorMessage} from '../utils';

const initialState = () => (
  {
    suppliers: [],
    numSuppliers: undefined,
    loadingSuppliers: false,
    loadingSuppliersError: undefined,

    addingSupplier: false,
    addingSupplierSuccess: undefined,
    addingSupplierError: undefined,

    selectedSupplier: undefined,
    loadingSelectedSupplier: false,
    loadingSelectedSupplierError: undefined,
    loadingSelectedSupplierSuccess: undefined,

    supplierProducts: [],
    loadingSupplierProducts: false,
    loadingSupplierProductsError: undefined,
    loadingSupplierProductsSuccess: undefined,

    productTypes: [],
    loadingProductTypes: false,
    loadingProductTypesError: undefined,
    loadingProductTypesSuccess: undefined,

    // redirects
    redirectToSupplierListView: false,
  }
);

// reducer
export default (state = initialState(), action) => {
  // always reset redirects
  state.redirectToSupplierListView = false;

  switch (action.type) {
    case constants.LIST_SUPPLIERS_REQUEST:
      return {...state, loadingSuppliers: true};

    case constants.LIST_SUPPLIERS_SUCCESS:
      return {
        ...state, 
        suppliers: action.payload.suppliers, 
        numSuppliers: action.payload.total, 
        loadingSuppliers: false,
        loadingSuppliersError: undefined
      };

    case constants.LIST_SUPPLIERS_FAILURE:
      return {
        ...state, 
        loadingSuppliers: false,
        loadingSuppliersError: extractApiErrorMessage(action.payload)
      };

    case constants.GET_SUPPLIER_REQUEST:
      return {
        ...state,
        selectedSupplier: undefined,
        loadingSelectedSupplier: true,
        loadingSelectedSupplierError: undefined,
        loadingSelectedSupplierSuccess: undefined
      }

    case constants.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        selectedSupplier: action.payload,
        loadingSelectedSupplier: false,
        loadingSelectedSupplierError: undefined,
        loadingSelectedSupplierSuccess: undefined
      }

    case constants.GET_SUPPLIER_FAILURE:
      return {
        ...state,
        selectedSupplier: undefined,
        loadingSelectedSupplier: false,
        loadingSelectedSupplierError: extractApiErrorMessage(action.payload),
        loadingSelectedSupplierSuccess: undefined
      }

    case constants.ADD_SUPPLIER_REQUEST:
      return {
        ...state, 
        addingSupplier: true,
        addingSupplierError: undefined,
        addingSupplierSuccess: undefined
      };

    case constants.ADD_SUPPLIER_SUCCESS:
      return {
        ...state, 
        addingSupplier: false,
        addingSupplierError: undefined,
        addingSupplierSuccess: `Successfully added supplier: ${action.payload.supplier_code}`
      };

    case constants.ADD_SUPPLIER_FAILURE:
      return {
        ...state, 
        addingSupplier: false,
        addingSupplierError: extractApiErrorMessage(action.payload),
        addingSupplierSuccess: undefined
      };

    case constants.DELETE_SUPPLIER_REQUEST:
      return {
        ...state, 
        loadingSelectedSupplier: false,
        loadingSelectedSupplierError: undefined,
        loadingSelectedSupplierSuccess: undefined
      };

    case constants.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state, 
        loadingSelectedSupplier: false,
        loadingSelectedSupplierError: undefined,
        loadingSelectedSupplierSuccess: `Successfully removed supplier: ${state.selectedSupplier.supplier_code}`,
        redirectToSupplierListView: true
      };

    case constants.DELETE_SUPPLIER_FAILURE:
      return {
        ...state, 
        loadingSelectedSupplier: false,
        loadingSelectedSupplierError: extractApiErrorMessage(action.payload),
        loadingSelectedSupplierSuccess: undefined
      };
    
    case constants.LIST_SUPPLIER_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingSupplierProducts: true,
        loadingSupplierProductsError: undefined,
        loadingSupplierProductsSuccess: undefined
      };
    
    case constants.LIST_SUPPLIER_PRODUCTS_SUCCESS:
      return {
        ...state,
        supplierProducts: action.payload.products,
        loadingSupplierProducts: false,
        loadingSupplierProductsError: undefined
      };

    case constants.LIST_SUPPLIER_PRODUCTS_FAILURE:
      return {
        ...state,
        loadingSupplierProducts: false,
        loadingSupplierProductsError: extractApiErrorMessage(action.payload)
    };
  
    case constants.DELETE_SUPPLIER_PRODUCT_REQUEST:
      return {
        ...state,
        loadingSupplierProducts: true,
        loadingSupplierProductsError: undefined,
        loadingSupplierProductsSuccess: undefined
      };
    
    case constants.DELETE_SUPPLIER_PRODUCT_SUCCESS:
      const supplierProducts = state.supplierProducts.filter(x => x.product_code !== action.payload.product_code);
      return {
        ...state,
        supplierProducts,
        loadingSupplierProducts: false,
        loadingSupplierProductsError: undefined,
        loadingSupplierProductsSuccess: action.payload.message
      };

    case constants.DELETE_SUPPLIER_PRODUCT_FAILURE:
      return {
        ...state,
        loadingSupplierProducts: false,
        loadingSupplierProductsError: extractApiErrorMessage(action.payload),
        loadingSupplierProductsSuccess: undefined
      };
  
    case constants.ADD_SUPPLIER_PRODUCT_REQUEST:
      return {
        ...state,
        loadingSupplierProducts: true,
        loadingSupplierProductsError: undefined,
        loadingSupplierProductsSuccess: undefined
      };
    
    case constants.ADD_SUPPLIER_PRODUCT_SUCCESS:
      state.supplierProducts.push(action.payload);
      return {
        ...state,
        loadingSupplierProducts: false,
        loadingSupplierProductsError: undefined,
        loadingSupplierProductsSuccess: `Successfully added product ${action.payload.product_code} to supplier`
      };

    case constants.ADD_SUPPLIER_PRODUCT_FAILURE:
      return {
        ...state,
        loadingSupplierProducts: false,
        loadingSupplierProductsError: extractApiErrorMessage(action.payload),
        loadingSupplierProductsSuccess: undefined
      };

    default:
      return state
  }
};
