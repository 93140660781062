export default {
    ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
    ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
    ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',

    LIST_PRODUCTS_REQUEST: 'LIST_PRODUCTS_REQUEST',
    LIST_PRODUCTS_SUCCESS: 'LIST_PRODUCTS_SUCCESS',
    LIST_PRODUCTS_FAILURE: 'LIST_PRODUCTS_FAILURE',

    GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

    UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILURE: 'UPDATE_PRODUCT_FAILURE',

    DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
    DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',

    EDIT_SELECTED_PRODUCT: 'EDIT_SELECTED_PRODUCT',

    /**
     * UI constants
     */
     TOGGLE_CLEAR_PRODUCT: 'TOGGLE_CLEAR_PRODUCT'
};