import constants from './constants';
import {extractApiErrorMessage} from '../utils';

const initialState = () => (
  {
    stock: [],
    numStock: undefined,
    loadingStock: false,
    loadingStockError: undefined,
    loadingStockSuccess: undefined,

    addingStock: false,
    addingStockSuccess: undefined,
    addingStockError: undefined,
  }
);

// reducer
export default (state = initialState(), action) => {
  switch (action.type) {
    case constants.LIST_STOCK_REQUEST:
      return {...state, loadingStock: true};

    case constants.LIST_STOCK_SUCCESS:
      return {
        ...state, 
        stock: action.payload.stock, 
        numStock: action.payload.total, 
        loadingStock: false,
        loadingStockError: undefined
      };

    case constants.LIST_STOCK_FAILURE:
      return {
        ...state, 
        loadingStock: false,
        loadingStockError: extractApiErrorMessage(action.payload)
      };

    case constants.ADD_STOCK_REQUEST:
      return {
        ...state, 
        addingStock: true,
        addingStockError: undefined,
        addingStockSuccess: undefined
      };

    case constants.ADD_STOCK_SUCCESS:
      return {
        ...state, 
        addingStock: false,
        addingStockError: undefined,
        addingStockSuccess: `Successfully added stock. PurchaseId: ${action.payload.purchase_id}`
      };

    case constants.ADD_STOCK_FAILURE:
      return {
        ...state, 
        addingStock: false,
        addingStockError: extractApiErrorMessage(action.payload),
        addingStockSuccess: undefined
      };
    

    default:
      return state
  }
};