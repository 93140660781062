export default {
    LIST_ORDERS_REQUEST: 'LIST_ORDERS_REQUEST',
    LIST_ORDERS_SUCCESS: 'LIST_ORDERS_SUCCESS',
    LIST_ORDERS_FAILURE: 'LIST_ORDERS_FAILURE',

    ADD_OR_UPDATE_ORDER_REQUEST: 'ADD_OR_UPDATE_ORDER_REQUEST',
    ADD_OR_UPDATE_ORDER_SUCCESS: 'ADD_OR_UPDATE_ORDER_SUCCESS',
    ADD_OR_UPDATE_ORDER_FAILURE: 'ADD_OR_UPDATE_ORDER_FAILURE',

    UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',

    GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',

    CANCEL_ORDER_REQUEST: 'CANCEL_ORDER_REQUEST',
    CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAILURE: 'CANCEL_ORDER_FAILURE',

    PATCH_ORDER_REQUEST: 'PATCH_ORDER_REQUEST',
    PATCH_ORDER_SUCCESS: 'PATCH_ORDER_SUCCESS',
    PATCH_ORDER_FAILURE: 'PATCH_ORDER_FAILURE',

    HANDLE_ORDER_CHANGED: 'HANDLE_ORDER_CHANGED',
    ADD_ORDER_LINE_ITEM: 'ADD_ORDER_LINE_ITEM',
    REMOVE_ORDER_LINE_ITEM: 'REMOVE_ORDER_LINE_ITEM',
    HANDLE_ORDER_LINE_ITEM_CHANGED: 'handleOrderLineItemChanged',
};