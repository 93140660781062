import constants from './constants';
import {extractApiErrorMessage} from '../utils';

const initialState = () => (
  {
    orderStats: undefined,
    loadingOrderStats: true,
    loadingOrderStatsError: undefined,
    
    topCustomersBySpendStats: undefined,
    loadingTopCustomersBySpendStats: true,
    loadingTopCustomersBySpendStatsError: undefined,
    
    topProductsByProfitStats: undefined,
    loadingTopProductsByProfitStats: true,
    loadingTopProductsByProfitStatsError: undefined
  }
);

// reducer
export default (state = initialState(), action) => {
  switch (action.type) {
    case constants.GET_ORDER_STATS_REQUEST:
      return {
        ...state,
        loadingOrderStats: true,        
      };

    case constants.GET_ORDER_STATS_SUCCESS:
      return {
        ...state,
        loadingOrderStats: false,
        orderStats: action.payload
      };

    case constants.GET_ORDER_STATS_FAILURE:
      return {
        ...state,
        loadingOrderStats: false,
        loadingOrderStatsError: extractApiErrorMessage(action.payload)
      };
    
    case constants.GET_CUSTOMER_STATS_REQUEST:
      return {
        ...state,
        loadingTopCustomersBySpendStats: true,        
      };

    case constants.GET_CUSTOMER_STATS_SUCCESS:
      return {
        ...state,
        loadingTopCustomersBySpendStats: false,
        topCustomersBySpendStats: action.payload,
        loadingTopCustomersBySpendStatsError: undefined
      };

    case constants.GET_CUSTOMER_STATS_FAILURE:
      return {
        ...state,
        loadingTopCustomersBySpendStats: false,
        loadingTopCustomersBySpendStatsError: extractApiErrorMessage(action.payload)
      };
    
    case constants.GET_PRODUCT_STATS_REQUEST:
      return {
        ...state,
        loadingTopProductsByProfitStats: true,        
      };

    case constants.GET_PRODUCT_STATS_SUCCESS:
      return {
        ...state,
        loadingTopProductsByProfitStats: false,
        topProductsByProfitStats: action.payload,
        loadingTopProductsByProfitStatsError: undefined
      };

    case constants.GET_PRODUCT_STATS_FAILURE:
      return {
        ...state,
        loadingTopProductsByProfitStats: false,
        loadingTopProductsByProfitStatsError: extractApiErrorMessage(action.payload)
      };

    default:
      return state
  }
};
