export default {
    LIST_USERS_REQUEST: 'LIST_USERS_REQUEST',
    LIST_USERS_SUCCESS: 'LIST_USERS_SUCCESS',
    LIST_USERS_FAILURE: 'LIST_USERS_FAILURE',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    EDIT_SELECTED_USER: 'EDIT_SELECTED_USER'
};