import {combineReducers} from 'redux';
import auth, * as authState from './auth/reducer';
import users from './users/reducer';
import products from './products/reducer';
import suppliers from './suppliers/reducer';
import customers from './customers/reducer';
import stock from './stock/reducer';
import orders from './orders/reducer';
import stats from './stats/reducer';

export default combineReducers({
  auth,
  users,
  products,
  suppliers,
  customers,
  stock,
  orders,
  stats
});

export const isAuthenticated =
  state => authState.isAuthenticated(state.auth)
export const accessToken =
  state => authState.accessToken(state.auth)
export const isAccessTokenExpired =
  state => authState.isAccessTokenExpired(state.auth)
export const refreshToken =
  state => authState.refreshToken(state.auth)
export const isRefreshTokenExpired =
  state => authState.isRefreshTokenExpired(state.auth)
export const authErrors =
  state => authState.errors(state.auth)
export const isAuthenticating =
  state => authState.loggingIn(state.auth)
export const withAuth = (headers = {}) => {
  return (state) => ({
    ...headers,
    'Authorization': `Bearer ${accessToken(state)}`
  })
}