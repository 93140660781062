export default {
    ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
    ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
    ADD_CUSTOMER_FAILURE: 'ADD_CUSTOMER_FAILURE',

    LIST_CUSTOMERS_REQUEST: 'LIST_CUSTOMERS_REQUEST',
    LIST_CUSTOMERS_SUCCESS: 'LIST_CUSTOMERS_SUCCESS',
    LIST_CUSTOMERS_FAILURE: 'LIST_CUSTOMERS_FAILURE',

    GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
    GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILURE: 'GET_CUSTOMER_FAILURE',

    UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
    UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',

    DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
    DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
    DELETE_CUSTOMER_FAILURE: 'DELETE_CUSTOMER_FAILURE',

    EDIT_SELECTED_CUSTOMER: 'EDIT_SELECTED_CUSTOMER',

    LIST_ADDRESSES_REQUEST: 'LIST_ADDRESSES_REQUEST',
    LIST_ADDRESSES_SUCCESS: 'LIST_ADDRESSES_SUCCESS',
    LIST_ADDRESSES_FAILURE: 'LIST_ADDRESSES_FAILURE',

    ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',

    UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',

    DELETE_ADDRESS_REQUEST: 'DELETE_ADDRESS_REQUEST',
    DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
    DELETE_ADDRESS_FAILURE: 'DELETE_ADDRESS_FAILURE',

    LIST_CONTACT_DETAILS_REQUEST: 'LIST_CONTACT_DETAILS_REQUEST',
    LIST_CONTACT_DETAILS_SUCCESS: 'LIST_CONTACT_DETAILS_SUCCESS',
    LIST_CONTACT_DETAILS_FAILURE: 'LIST_CONTACT_DETAILS_FAILURE',

    ADD_CONTACT_DETAIL_REQUEST: 'ADD_CONTACT_DETAIL_REQUEST',
    ADD_CONTACT_DETAIL_SUCCESS: 'ADD_CONTACT_DETAIL_SUCCESS',
    ADD_CONTACT_DETAIL_FAILURE: 'ADD_CONTACT_DETAIL_FAILURE',

    UPDATE_CONTACT_DETAIL_REQUEST: 'UPDATE_CONTACT_DETAIL_REQUEST',
    UPDATE_CONTACT_DETAIL_SUCCESS: 'UPDATE_CONTACT_DETAIL_SUCCESS',
    UPDATE_CONTACT_DETAIL_FAILURE: 'UPDATE_CONTACT_DETAIL_FAILURE',

    DELETE_CONTACT_DETAIL_REQUEST: 'DELETE_CONTACT_DETAIL_REQUEST',
    DELETE_CONTACT_DETAIL_SUCCESS: 'DELETE_CONTACT_DETAIL_SUCCESS',
    DELETE_CONTACT_DETAIL_FAILURE: 'DELETE_CONTACT_DETAIL_FAILURE',

    SET_ADD_CONTACT_DETAIL_MODE: 'SET_ADD_CONTACT_DETAIL_MODE',
    SET_EDIT_CONTACT_DETAIL_MODE: 'SET_EDIT_CONTACT_DETAIL_MODE',
    EDIT_CONTACT_DETAIL_FIELDS: 'EDIT_CONTACT_DETAIL_FIELDS',

    /**
     * UI constants
     */
     TOGGLE_CLEAR_CUSTOMER: 'TOGGLE_CLEAR_CUSTOMER'
};