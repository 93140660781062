import React from 'react';
import {
  Input, 
  Alert, 
  FormGroup, 
  Label, 
  Table, 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  CardBody, 
  Pagination, 
  PaginationItem, 
  PaginationLink,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const formatter = new Intl.NumberFormat('en-US', {    
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const formatDate = (datseStr) => {
  const d = new Date(datseStr);

  const year = d.getFullYear();
  const date = d.getDate();
  const month = months[d.getMonth()];
  const dayOfWeek = days[d.getDay()];

  return `${dayOfWeek}, ${month} ${date}, ${year}`
}

export const formatZAR = (num) => formatter.format(num);

export const formatNewlines = (str) => <div style={{whiteSpace: "pre-wrap"}}>{str}</div>

export const handleInputChange = (component, event) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  component.setState({[name]: value});

  return value;
};

export const renderRolesSelect = (allRoles, selectedRole, inputChangeFunc) => {
  const options = allRoles.map(role => <option key={role} value={role}>{role}</option>);
  return (
    <Input name="role" type="select" onChange={inputChangeFunc} value={selectedRole}>
      <option key="default" value="">Select a role</option>
      {options}
    </Input>
  );
}

export const renderContactTypesSelect = (allContactTypes, selectedContactType, inputChangeFunc, disabled=false) => {
  const options = allContactTypes.map(contactType => 
    <option key={contactType.contactType} value={contactType.contactType}>{contactType.displayText}</option>);
  return (
    <Input disabled={disabled} name="contact_type" type="select" onChange={inputChangeFunc} value={selectedContactType}>
      <option key="default" value="">Select a contact type</option>
      {options}
    </Input>
  );
}

export const renderTable = (headings, items, rowFunc, rowsToAppend=[], rowsToPrepend=[]) => {
  const rows = items.length > 0 ? 
    items.map(rowFunc) : 
    [         
      <tr key="empty">
        <th scope="row" colSpan={headings.length}>No data available</th>
      </tr>  
    ]
  return <Table responsive hover>
    <thead>
      <tr>
        {headings.map((heading, index) => <th key={index} scope="col">{heading}</th>)}
      </tr>
    </thead>
    <tbody>
      {rowsToPrepend}
      {rows}
      {rowsToAppend}
    </tbody>
  </Table>
};

export const renderSelect = (name, allOptions, selectedOption, inputChangeFunc, disabled=false, placeholder="Select an option") => {
  const options = allOptions.map((option, index) => 
    <option key={index} value={option.value}>{option.text}</option>);
  return (
    <Input disabled={disabled} name={name} type="select" onChange={inputChangeFunc} value={selectedOption}>
      <option key="default" value="">{placeholder}</option>
      {options}
    </Input>
  );
}

export const renderRadioButtons = (options, key, selectedOption, inputChangeFunc, inline=false) => {
  return options.map((option, index) => (
    <FormGroup inline={inline} check key={index}>
      <Input className="form-check-input" type="radio" id={key + "-" + index} name={key} value={option} checked={selectedOption === option} onChange={inputChangeFunc} />
      <Label className="form-check-label" check htmlFor={key + "-" + index}>{option}</Label>
    </FormGroup>
  ));
}

export const renderLoading = () => (
  <div className="animated fadeIn pt-1 text-center">
    <strong><i className="fa fa-circle-o-notch fa-spin"></i> Loading...</strong>
  </div>
);

export const renderErrorBanner = (message) => (
  <Alert color="danger" key="error">
    Error: {message}
  </Alert>
);

export const renderSuccessBanner = (message) => (
  <Alert color="success" key="success">
    {message}
  </Alert>
);

export const renderCard = (header, body, lg=12) => (
  <div className="animated fadeIn">
    <Row>
      <Col lg={lg}>
        <Card>
          <CardHeader>
              {header}
          </CardHeader>
          <CardBody>
            <Row className="align-items-center mt-3">
              <Col>
                {body}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export const renderPagination = (numResults, pageSize, currentPage, goToPageFunc) => {
  const lastPage = Math.ceil(numResults/pageSize);

  return <Pagination style={{float: "right"}}>
  <PaginationItem>
    <PaginationLink previous tag="button" disabled={currentPage <= 1} onClick={() => goToPageFunc(1)} />
  </PaginationItem>
    <PaginationItem>
      <PaginationLink tag="button" disabled={currentPage <= 1} onClick={() => goToPageFunc(currentPage - 1)}>
        ‹
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink disabled tag="button">
        {currentPage} of {lastPage}
      </PaginationLink>
    </PaginationItem>
  <PaginationItem>
    <PaginationLink tag="button" disabled={currentPage >= lastPage} onClick={() => goToPageFunc(currentPage + 1)}>
      ›
    </PaginationLink>
  </PaginationItem>
    <PaginationItem>
      <PaginationLink next tag="button" disabled={currentPage >= lastPage} onClick={() => goToPageFunc(lastPage)}/>
    </PaginationItem>
  </Pagination>
}

export const renderFilterInput = (component) => {
  const isOpen = component.state.isOpen;
  const selectedFilterField = component.state.selectedFilterField;
  const filterFields = component.state.filterFields;
  console.log(component.filterValue);

  const toggleSearchDropdown = () => {
    component.setState({isOpen: !component.state.isOpen});
  }

  const selectFilterField = (field) => {
    component.setState({selectedFilterField: field});
  }

  const filterFunc = () => {
    component.loadPage(1);
  }

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      filterFunc();
    }
  }

  return <FormGroup>
    <div className="controls">
      <InputGroup>
        <InputGroupButtonDropdown
            addonType="prepend"
            isOpen={isOpen}
            toggle={toggleSearchDropdown}>
            <DropdownToggle caret color="primary">Filter by {selectedFilterField.displayText}</DropdownToggle>
            <DropdownMenu className={isOpen ? 'show' : ''}>
              {filterFields.map((field, index) => (
                <DropdownItem key={index} onClick={() => selectFilterField(field)}>{field.displayText}</DropdownItem>
              ))}
            </DropdownMenu>
          </InputGroupButtonDropdown>
        <Input placeholder={"Enter a " + selectedFilterField.displayText} size="16" type="text" name="filterValue" onChange={(e) => handleInputChange(component, e)} onKeyPress={onEnterKeyPress}  value={component.state.filterValue} />
        <InputGroupAddon addonType="append">
          <Button color="primary" outline onClick={filterFunc}><i className="fa fa-filter"></i> Filter</Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  </FormGroup>
}