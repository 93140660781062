import constants from './constants';
import {extractApiErrorMessage} from '../utils';

const initialState = () => (
  {
    orders: [],
    numOrders: undefined,
    loadingOrders: false,
    loadingOrdersError: undefined,
    loadingOrdersSuccess: undefined,

    addingOrder: false,
    addingOrderSuccess: undefined,
    addingOrderError: undefined,

    selectedOrder: undefined,
    loadingSelectedOrder: true,
    loadingSelectedOrderError: undefined,
    loadingSelectedOrderSuccess: undefined,

    redirectToOrderListView: false,
    redirectToOrderId: undefined,
  }
);

const buildOrderLineItem = (supplier_code="", product_code="", quantity=0, sale_price="", discount=0) => ({
  supplier_code,
  product_code,
  quantity,
  sale_price,
  discount
});

const getOrderLineItemsFromOrder = (order) => {
  const orderLineItems = {};
  order.products.forEach(p => {
    const key = `${p.supplier_code}-${p.product_code}-${p.sale_price}-${p.discount}`
    const lineItem = orderLineItems[key] !== undefined ?
      orderLineItems[key] :
      buildOrderLineItem(p.supplier_code, p.product_code, 0, p.sale_price, p.discount);
    
    lineItem.quantity += 1;

    orderLineItems[key] = lineItem;
  });
  return Object.values(orderLineItems);
}

// reducer
export default (state = initialState(), action) => {
  state.redirectToOrderListView = false;
  state.redirectToOrderId = undefined;

  switch (action.type) {
    case constants.LIST_ORDERS_REQUEST:
      return {
        ...state,
        loadingOrders: true,
    };

    case constants.LIST_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders, 
        numOrders: action.payload.total, 
        loadingOrders: false,
        loadingOrdersError: undefined,
      };

    case constants.LIST_ORDERS_FAILURE:
      return {
        ...state, 
        loadingOrders: false,
        loadingOrdersError: extractApiErrorMessage(action.payload),
      };

    case constants.ADD_OR_UPDATE_ORDER_REQUEST:
      return {
        ...state, 
        addingOrder: true,
        addingOrderError: undefined,
        addingOrderSuccess: undefined,
      };

    case constants.ADD_OR_UPDATE_ORDER_SUCCESS:
      return {
        ...state, 
        addingOrder: false,
        addingOrderError: undefined,
        redirectToOrderId: action.payload.order_id,
      };

    case constants.ADD_OR_UPDATE_ORDER_FAILURE:
      return {
        ...state, 
        addingOrder: false,
        addingOrderError: extractApiErrorMessage(action.payload),
        addingOrderSuccess: undefined,
      };

    case constants.GET_ORDER_REQUEST:
      return {
        ...state, 
        selectedOrder: undefined,
        loadingSelectedOrder: true,
        loadingSelectedOrderError: undefined,
        loadingSelectedOrderSuccess: undefined,
      };

    case constants.GET_ORDER_SUCCESS:
      const order = action.payload;
      order.orderLineItems = getOrderLineItemsFromOrder(order);
      order.order_date = order.order_date.split("T")[0];
      return {
        ...state, 
        selectedOrder: action.payload,
        loadingSelectedOrder: false,
        loadingSelectedOrderError: undefined,
        loadingSelectedOrderSuccess: undefined,
      };

    case constants.GET_ORDER_FAILURE:
      return {
        ...state, 
        loadingSelectedOrder: false,
        loadingSelectedOrderError: extractApiErrorMessage(action.payload),
        loadingSelectedOrderSuccess: undefined,
      };

    case constants.CANCEL_ORDER_REQUEST:
      return {
        ...state, 
        loadingSelectedOrder: true,
        loadingSelectedOrderError: undefined,
        loadingSelectedOrderSuccess: undefined,
      };

    case constants.CANCEL_ORDER_SUCCESS:
      return {
        ...state, 
        selectedOrder: undefined,
        loadingSelectedOrderError: undefined,
        loadingSelectedOrderSuccess: undefined,
        redirectToOrderListView: true
      };

    case constants.CANCEL_ORDER_FAILURE:
      return {
        ...state, 
        loadingSelectedOrder: false,
        loadingSelectedOrderError: extractApiErrorMessage(action.payload),
        loadingSelectedOrderSuccess: undefined,
      }; 

    case constants.PATCH_ORDER_REQUEST:
      return {
        ...state, 
        loadingSelectedOrder: true,
        loadingSelectedOrderError: undefined,
        loadingSelectedOrderSuccess: undefined,
      };

    case constants.PATCH_ORDER_SUCCESS:
      return {
        ...state, 
        selectedOrder: {...state.selectedOrder, ...action.payload},
        loadingSelectedOrder: false,
        loadingSelectedOrderError: undefined,
        loadingSelectedOrderSuccess: undefined,
      };

    case constants.PATCH_ORDER_FAILURE:
      return {
        ...state, 
        loadingSelectedOrder: false,
        loadingSelectedOrderError: extractApiErrorMessage(action.payload),
        loadingSelectedOrderSuccess: undefined,
      };
    
    case constants.HANDLE_ORDER_CHANGED:
      const selectedOrder = {...state.selectedOrder};
      selectedOrder[action.payload.field] = action.payload.value;
      return {
        ...state,
        selectedOrder
      }
    
    case constants.ADD_ORDER_LINE_ITEM:
      const orderToAddLineItem = {...state.selectedOrder};
      orderToAddLineItem.orderLineItems.push(buildOrderLineItem());
      return {
        ...state,
        selectedOrder: orderToAddLineItem
      }
    
    case constants.REMOVE_ORDER_LINE_ITEM:
      const orderToRemoveLineItem = {...state.selectedOrder};
      orderToRemoveLineItem.orderLineItems.splice(action.payload.index, 1)
      return {
        ...state,
        selectedOrder: orderToRemoveLineItem
      }
    
    case constants.HANDLE_ORDER_LINE_ITEM_CHANGED:
      const updateLineItemOrder = {...state.selectedOrder};
      const orderLineItemToUpdate = updateLineItemOrder.orderLineItems[action.payload.index];
      orderLineItemToUpdate[action.payload.field] = action.payload.value
      return {
        ...state,
        selectedOrder: updateLineItemOrder
      }

    default:
      return state
  }
};