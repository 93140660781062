import constants from './constants';

const initialState = () => (
  {
    products: [],
    numProducts: undefined,
    loadingProducts: false,
    loadingProductsError: undefined,
    loadingProductsSuccess: undefined,

    addingProduct: false,
    addingProductSuccess: undefined,
    addingProductError: undefined,
    clearProduct: false,

    selectedProduct: undefined,
    editedProduct: undefined,
    loadingSelectedProduct: false,
    loadingSelectedProductSuccess: undefined,
    loadingSelectedProductError: undefined,

    // redirects
    redirectToProductListView: false,
  }
);

// reducer
export default (state = initialState(), action) => {
  // always reset redirects
  state.redirectToProductListView = false;

  switch (action.type) {
    case constants.LIST_PRODUCTS_REQUEST:
      return {...state, loadingProducts: true};

    case constants.LIST_PRODUCTS_SUCCESS:
      return {
        ...state, 
        products: action.payload.products, 
        numProducts: action.payload.total, 
        loadingProducts: false,
        loadingProductsError: undefined
      };

    case constants.LIST_PRODUCTS_FAILURE:
      return {
        ...state, 
        loadingProducts: false,
        loadingProductsError: action.payload.message
      };

    case constants.ADD_PRODUCT_REQUEST:
      return {
        ...state, 
        addingProduct: true,
        addingProductError: undefined,
        addingProductSuccess: undefined
      };

    case constants.ADD_PRODUCT_SUCCESS:
      return {
        ...state, 
        addingProduct: false,
        addingProductError: undefined,
        addingProductSuccess: `Successfully added product: ${action.payload.product_code}`,
        clearProduct: true
      };

    case constants.ADD_PRODUCT_FAILURE:
      return {
        ...state, 
        addingProduct: false,
        addingProductError: action.payload.response.message || action.payload.message,
        addingProductSuccess: undefined
      };
    
    case constants.TOGGLE_CLEAR_PRODUCT:
      return {...state, clearProduct: !state.clearProduct}
    
    case constants.GET_PRODUCT_REQUEST:
      return {
        ...state,
        loadingSelectedProduct: true,
        loadingSelectedProductSuccess: undefined,
        loadingSelectedProductError: undefined
      }
    
    case constants.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProduct: action.payload,
        editedProduct: action.payload,
        loadingSelectedProduct: false,
        loadingSelectedProductSuccess: undefined,
        loadingSelectedProductError: undefined
      }
    
    case constants.GET_PRODUCT_FAILURE:
      return {
        ...state,
        selectedProduct: undefined,
        editedProduct: undefined,
        loadingSelectedProduct: false,
        loadingSelectedProductSuccess: undefined,
        loadingSelectedProductError: action.payload.response.message || action.payload.message
      }

    case constants.UPDATE_PRODUCT_REQUEST:
    case constants.DELETE_PRODUCT_REQUEST:
      return {
        ...state, 
        loadingSelectedProduct: true,
        loadingSelectedProductError: undefined,
        loadingSelectedProductSuccess: undefined
      };

    case constants.UPDATE_PRODUCT_FAILURE:
    case constants.DELETE_PRODUCT_FAILURE:
      return {
        ...state, 
        loadingSelectedProduct: false,
        loadingSelectedProductError: action.payload.response.message || action.payload.message,
        loadingSelectedProductSuccess: undefined
      };

    case constants.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingSelectedProduct: false,
        loadingSelectedProductError: undefined,
        loadingSelectedProductSuccess: `Successfully updated product: ${state.selectedProduct.product_code}`,
        selectedProduct: action.payload,
        editedProduct: action.payload
      };

    case constants.DELETE_PRODUCT_SUCCESS:
      return {
        ...state, 
        loadingSelectedProduct: false,
        loadingSelectedProductError: undefined,
        loadingSelectedProductSuccess: undefined,
        redirectToProductListView: true,
        selectedProduct: undefined,
        editedProduct: undefined
      };
    
    case constants.EDIT_SELECTED_PRODUCT:
      const editedProduct = {...state.editedProduct, ...action.payload};
      return {...state, editedProduct}

    default:
      return state
  }
};
